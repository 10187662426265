<template>
    <div style="position: relative" class="col-xl-7 col-12">
        <v-overlay
            :value="loading.logs"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    />
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions,
                showFirstLastPage: true
            }"
            :headers="headers"
            :items="allRows"
            :options="tableOptions"
            :server-items-length="totalRows"
            calculate-widths
            class="appic-table-light specification-table"
            dense
            id="userLogTable"
            item-key="UserLog.id"
            sort-by="UserLog.timestamp"
            sort-desc
            @update:options="updateDataTable"
        >
            <template v-slot:top>
                <div class="flex flex-row pa-0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon @click="reloadHistory()" v-on="{ ...tooltip }" :loading="reloading">
                                <v-icon>refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.reload') }}</span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:item.UserLog.timestamp="{ item }">
                <span class="text-no-wrap">{{ item.UserLog.timestamp }}</span>
            </template>
            <template v-slot:item.UserLog.user="{ item }">
                <span class="font-weight-bold text-no-wrap">{{ capitalize(item.UserLog.user) }}</span>
            </template>
            <template v-slot:item.UserLog.document="{ item }">
                <span class="text-no-wrap">{{ item.UserLog.document ? item.UserLog.document : $t('message.na') }}</span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {capitalize} from "Helpers/helpers";
import {api} from "Api";

export default {
    name: "AddUser",
    props: ['userId'],
    data() {
        return {
            allRows: [],
            loader: false,
            loading: {
                filterItems: {
                    user: false,
                },
                search: false,
                logs: false
            },
            reloading: false,
            tableOptions: {},
            totalRows: 0,
        }
    },
    computed: {
        defaultItemsPerPage() {
            if(window.screen.height >= 800) return 20
            return 15
        },
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.timestamp'),
                    value: 'UserLog.timestamp',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: true
                },
                {
                    id: 2,
                    text: this.$t('message.user'),
                    value: 'UserLog.user',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.action'),
                    value: 'UserLog.event',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.document'),
                    value: 'UserLog.document',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.ip'),
                    value: 'UserLog.ip',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 6,
                    text: this.$t('message.approxLocation'),
                    value: 'UserLog.location',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                }
            ]
        },
        itemsPerPageOptions() {
            return [10,20,50,100];
        },
    },
    methods: {
        capitalize,
        getUserActivity() {
            if(this.tableOptions.page != null) {
                this.loading.logs = true
                api
                    .get('/admin/user-log/' + this.userId, {
                        params: {
                            pagination: {
                                skip: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
                                take: this.tableOptions.itemsPerPage
                            },
                            order: [
                                {
                                    field: 'UserLog.timestamp',
                                    direction: 'DESC'
                                }
                            ]
                        }
                    })
                    .then((response) => {
                        if (response.data.status == 'success') {
                            this.allRows = response.data.data
                            this.totalRows = response.data.totalRows
                        } else {
                            this.$toast.error(this.$t('message.errors.logNotLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.loading.logs = false
                        if(this.reloading) this.reloading = false
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.logNotLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.logs = false
                        if(this.reloading) this.reloading = false
                    })
            } else {
                if(this.reloading) this.reloading = false
            }
        },
        reloadHistory() {
            this.reloading = true
            this.tableOptions.page = 1
            this.getUserActivity()
        },
        updateDataTable(options) {
            this.tableOptions = options
            this.getUserActivity()
        }
    },

}
</script>

<style scoped>

</style>