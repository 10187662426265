var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xl-7 col-12",staticStyle:{"position":"relative"}},[_c('v-overlay',{attrs:{"value":_vm.loading.logs,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"appic-table-light specification-table",attrs:{"footer-props":{
            itemsPerPageOptions: _vm.itemsPerPageOptions,
            showFirstLastPage: true
        },"headers":_vm.headers,"items":_vm.allRows,"options":_vm.tableOptions,"server-items-length":_vm.totalRows,"calculate-widths":"","dense":"","id":"userLogTable","item-key":"UserLog.id","sort-by":"UserLog.timestamp","sort-desc":""},on:{"update:options":_vm.updateDataTable},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"flex flex-row pa-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":_vm.reloading},on:{"click":function($event){return _vm.reloadHistory()}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('message.reload')))])])],1)]},proxy:true},{key:"item.UserLog.timestamp",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.UserLog.timestamp))])]}},{key:"item.UserLog.user",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold text-no-wrap"},[_vm._v(_vm._s(_vm.capitalize(item.UserLog.user)))])]}},{key:"item.UserLog.document",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.UserLog.document ? item.UserLog.document : _vm.$t('message.na')))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }